
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import AutomationRuleGroup from '@/modules/automation/components/RuleGroup.vue'
import RulesList from './RulesList.vue'

const { VUE_APP_DASH } = process.env

@Component({ components: { AutomationRuleGroup, RulesList } })
export default class Automation extends VueStrong {
  shareDialog = false
  snackbarError = false
  snackbarSuccess = false
  loading = false
  readMore = true

  copyToClipboardError = false
  copyToClipboardSuccess = false

  automation: null | yakkyo.IFacebookAutomation | any = null
  rules: any = []

  get isNotYourStrategy() {
    return this.automation && this.automation.adAccountId !== this.accountId
  }

  get accountId() {
    return this.$store.state.account.adAccount?.id
  }

  get link() {
    const baseUrl = VUE_APP_DASH || 'https://marketing.yakkyofy.com'
    return baseUrl + this.$route.path
  }

  get showUpdateButton() {
    if (this.isAuthenticated && this.automation && this.automation.author)
      if (this.automation.author._id === this.user.customer) return true
    return false
  }

  get user() {
    return this.$store.state.account.accounts[0]
  }

  get isAuthenticated() {
    return this.$store.getters.isAuthenticated
  }

  get isShareable() {
    return !!this.automation.shareable
  }

  get automationId() {
    return this.$route.params.id
  }

  get descriptionBySentences() {
    const senteces = this.automation.description.split('.')
    senteces.pop()
    return senteces.length ? senteces : [this.automation.description]
  }

  get parsedEntityRules() {
    let res = { name: '', statuses: '' }

    if (this.automation.entityRules && this.automation.entityRules.length) {
      const name = this.automation.entityRules.find(r => r.field === 'name')
      if (name) res.name = name.value
      const statuses = this.automation.entityRules.find(r => r.field === 'status')
      if (statuses) res.statuses = statuses.value.replace(/\[|\]/g, '').split(',')
    }
    return res
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  async mounted() {
    if (!this.isAuthenticated) await this.getPublicAutomation()
    else await this.getAutomation()
  }

  async getPublicAutomation() {
    this.loading = true
    try {
      const { data } = await this.$store.state.apiClient.getPublicAutomation(this.automationId)
      this.automation = data
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    }
    this.loading = false
  }

  async getAutomation() {
    this.loading = true
    try {
      const { data } = await this.$store.state.apiClient.getAutomation(this.automationId)
      this.automation = data
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    }
    this.loading = false
  }

  hasRules(rule) {
    if (rule.rules.length || rule.rulesGroups.length) return true
    else return false
  }

  copyToClipboard() {
    if (!navigator.clipboard) return (this.copyToClipboardError = true)
    navigator.clipboard.writeText(this.link)
    this.copyToClipboardSuccess = true
  }

  async onShareableChange() {
    this.loading = true
    try {
      await this.$store.dispatch('automation/update', {
        id: this.automation._id,
        automation: { shareable: !this.automation.shareable }
      })
      this.getAutomation()
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  async duplicateStrategy() {
    this.loading = true
    try {
      const response = await this.$store.dispatch('automation/duplicate', this.automation._id)
      this.snackbarSuccess = true
      this.$router.push({ name: 'Automation', params: { id: response._id, public: 'false' } })
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  useStrategy() {
    if (!this.isAuthenticated) this.$router.push('/?redirect=' + window.location.pathname)
    else this.duplicateStrategy()
  }

  getActionFrequency(minutes: number) {
    if (minutes <= 60) return `(Once every ${minutes} minutes)`
    else return `(Once every ${Math.ceil(minutes / 60)} hours)`
  }
}
