
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import AutomationRule from '@/modules/automation/components/Rule.vue'
import metrics from '@/modules/automation/data/metrics'

@Component({
  name: 'RulesList',
  components: {
    AutomationRule
  }
})
export default class RulesList extends VueStrong {
  @Prop({ type: Object, required: true }) group!: yakkyo.IFacebookAutomationRuleGroup
  @Prop({ type: String, required: true }) entityType!: FB.EntityTypes

  get metrics() {
    return metrics as any
  }

  parseOperator(operator: 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'LESS_THAN' | 'CONTAIN' | 'NOT_CONTAIN') {
    switch (operator) {
      case 'EQUAL':
        return 'is equal to'
      case 'NOT_EQUAL':
        return 'is not equal to'
      case 'GREATER_THAN':
        return 'is greater than'
      case 'LESS_THAN':
        return 'is less than'
      case 'CONTAIN':
        return 'contains'
      case 'NOT_CONTAIN':
        return 'does not contain'
    }
  }

  parseMetric(field: string) {
    const metricLevel = field.split('$')[0]
    const metricId = field.split('$')[1]

    const items = metrics.map(m => m.items).flat()
    const metric = items.find(item => item.id === metricId)

    return { metricLevel, name: metric ? metric.name : 'Unknown' }
  }
}
